import React from "react";
import TripApproval from "./trip";
import FlightApproval from "./flight";
import StayApproval from "./stay";
import ReceiptApproval from "./receipt";

function ApprovalTypes({ type, request = {}, receipt = {} }) {
	if (type === "flight") {
		return <FlightApproval {...request} />;
	}
	if (type === "stay") {
		return <StayApproval {...request} />;
	}
	if (type === "expense") {
		return <ReceiptApproval receipt={receipt} />;
	}
	if (type === "trip") {
		return <TripApproval {...request} />;
	}
	return null;
}

export default ApprovalTypes;

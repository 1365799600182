// import AddGSTIN from "./addGst";
import React from "react";
import { CustomFields, Spinner } from "../../../../../../../../Components";
import FlightCard from "./flightCard";

function ReviewStep({ loading, flight = {}, getCustomFields, onGSTChange = () => {}, gstin = { gstin }, costCenter, setCostCenter }) {
	const { segments, duration, returnSegments, returnDuration } = flight;

	if (loading) {
		return (
			<div className="w-full mt-4 flex-center">
				<Spinner />
			</div>
		);
	}

	return (
		<>
			<FlightCard baggage={""} segments={segments} duration={duration} />
			{returnSegments?.length > 0 && <FlightCard baggage={""} segments={returnSegments} duration={returnDuration} />}
			<div className="mt-4">
				{/* <CostCenterSearch className="p-4 mb-4 border rounded-lg" label="Select Cost center" onSelect={setCostCenter} selected={costCenter} /> */}
				{/* <CustomFields className="p-4 mb-4 border rounded-lg" getCustomFields={getCustomFields} activity="FLIGHT_BOOK" /> */}
				{/* <AddGSTIN gstin={gstin} onChange={onGSTChange} /> */}
			</div>
		</>
	);
}

export default ReviewStep;

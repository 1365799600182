import React, { useState } from "react";
import { Button, Container, ErrorMsg, Form, Input, Toast } from "../../../../../Components";
import { FiInfo } from "react-icons/fi";
import { apis, patch } from "../../../../../Utils/axios";
import { useLoginHook } from "../../../../../Hooks/useLoginHook";
import { isPasswordStrong } from "Utils/utils";

function ChangePassword() {
	const { logout } = useLoginHook();
	const [Values, setValues] = useState({
		old: "",
		password: "",
		confirm: "",
	});
	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();

	const updatePassword = () => {
		if (Values.password !== Values.confirm) {
			return setError("Password does not match");
		}
		setError();
		setLoader(true);
		if (!isPasswordStrong(Values.password)) {
			setError("Password must be 6 characters long with one special character and one uppercase letter and one number");
			setLoader();
			return;
		}
		patch(
			apis.changePassword,
			{
				email: Values.email,
				oldPassword: Values.old,
				password: Values.password,
			},
			(r, e) => {
				if (r) {
					Toast.success("Password Change, Login again");
					setLoader();
					logout();
				} else if (e) {
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	return (
		<Container variant="outlined">
			<div className="flex items-start justify-between">
				<h4 className="mb-4">Change Password</h4>
				<div className="mb-4 text-xs align-center text-secondary">
					<FiInfo className="mr-1" />
					Last changed on 02 Feb 2023
				</div>
			</div>
			<Form onSubmit={updatePassword} loading={Loader}>
				<Input type="password" className="mb-4" value={Values.old} onChange={(old) => setValues((prev) => ({ ...prev, old }))} required label="Old Password" placeholder="Old Password" />
				<div className="md:grid md:grid-cols-2 md:gap-x-4">
					<Input type="password" className="mb-4" value={Values.password} onChange={(password) => setValues((prev) => ({ ...prev, password }))} required label="New Password" placeholder="Password" />
					<Input type="password" value={Values.confirm} onChange={(confirm) => setValues((prev) => ({ ...prev, confirm }))} required label="Confirm New Password" placeholder="Confirm Password" />
				</div>
				<ErrorMsg e={Error} />
				<Button loading={Loader} type="submit" className="w-full mt-2">
					Change Password
				</Button>
			</Form>
		</Container>
	);
}

export default ChangePassword;

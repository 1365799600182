import React, { useRef, useState } from "react";
import { AnimatedTick, Button, CustomFields, Modal, Toast } from "../../../../../../../../Components";
import FlightCard from "./flightCard";
import { apis, post } from "../../../../../../../../Utils/axios";
import { FiMail } from "react-icons/fi";

function SendToAdmin({ flight, fare, type, approvalId }) {
	const [ShowPrompt, setShowPrompt] = useState();
	const [Success, setSuccess] = useState();
	const NOTES = useRef();
	const GET_CUSTOM_FIELDS = useRef();

	const sendRequest = () => {
		const customFields = GET_CUSTOM_FIELDS?.current?.();
		if (!customFields) {
			//Required custom fields not filled
			return false;
		}
		post(
			apis.requestOnewayFlight,
			{
				flight,
				fare,
				type,
				notes: NOTES.current.value,
				customFields,
				approvalId,
			},
			(r, e) => {
				if (r) {
					setSuccess(true);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	return (
		<>
			<Button onClick={() => setShowPrompt(true)} variant="primary" className="w-full">
				<FiMail className="mr-2" /> Send to Travel Head
			</Button>
			<Modal open={ShowPrompt} onClose={() => setShowPrompt(false)} contentClass="!max-w-[30rem]">
				{Success ? (
					<div className="p-8">
						<AnimatedTick />
						<h1 className="flex-center mt-4">Booking Request Sent</h1>
						<div className="text-sm text-center">We have sent a request to travel head. You can track the status of booking in Flight bookings</div>
					</div>
				) : (
					<div className="p-6">
						<h1 className="align-center gap-2">
							<FiMail />
							Send to Travel Head
						</h1>
						<div className="p-2 text-sm">If you want to book this flight you can send a booking request to travel head to book it for you.</div>
						<div className="rounded-md border mt-4">
							<FlightCard {...flight} />
						</div>
						<div className="flex flex-col gap-2 mt-4">
							<label>Notes (Optional)</label>
							<textarea ref={NOTES} className="input" placeholder="Notes for the travel head" />
						</div>
						<CustomFields className="mt-4" getCustomFields={GET_CUSTOM_FIELDS} activity="FLIGHT_BOOK" />
						<Button onClick={sendRequest} className="mt-6 w-full" variant="primary">
							Yes, Send to Admin
						</Button>
					</div>
				)}
			</Modal>
		</>
	);
}

export default SendToAdmin;

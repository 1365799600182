import React, { useState } from "react";
import { Badge, Button, Modal } from "../../../../../../../../Components";
import { apis, get } from "Utils/axios";

function FareRules({ refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }) {
	const [Rules, setRules] = useState(null);
	const [Loading, setLoading] = useState(false);
	const [ShowRules, setShowRules] = useState(false);

	const getFareRules = () => {
		setShowRules(true);
		if (Rules) return;
		setLoading(true);
		get(apis.getFareRules, { from: fromCode?.toUpperCase(), to: toCode?.toUpperCase(), date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }, (r) => {
			const rules = r.fareRules?.fareRuleResponseBean?.headingWiseList;
			setLoading(false);
			if (!rules) return;
			setRules(rules);
		});
	};

	return (
		<>
			<h6>Cancellation Policy</h6>
			{refundable ? (
				<div>
					<Badge className="mt-2" variant="success">
						Refundable
					</Badge>
					<p className="mt-2 text-xs text-secondary">This flight is eligible for refund on cancellation, please check the fare rules for more details</p>
				</div>
			) : (
				<Badge className="mt-2" variant="warning">
					Non-Refundable
				</Badge>
			)}
			<Button variant="outlined" onClick={getFareRules} className="w-full mt-2 btn-xs">
				View Fare Rules
			</Button>
			<Modal title="Fare Rules" onClose={() => setShowRules(false)} open={ShowRules} contentClass="p-6">
				{Loading ? (
					<h5 className="text-center">Loading Fare Rules...</h5>
				) : (
					Rules?.map((rule, index) => {
						return (
							<div key={index} className="mb-6 space-y-2">
								<h5>{rule.heading.split(".")[1]}</h5>
								{rule.ruleDescription.split("--").map((desc, index) => {
									return (
										<p key={index} className="text-xs text-secondary">
											{desc}
										</p>
									);
								})}
							</div>
						);
					})
				)}
			</Modal>
		</>
	);
}

export default FareRules;

import React, { useEffect, useState } from "react";
import { apis, patch } from "../../Utils/axios";
import { Button, Form, Input, Modal, Select, Toast, Avatar } from "..";
import Stamp from "../../Assets/Images/stamp.png";
import { TbBook } from "react-icons/tb";
import { COUNTRIES } from "../../Utils/constants";

function EditPassport({ reload = () => {}, photo, identification = {}, userId, guestId }) {
	const { passportNumber, passportIssueDate, passportExpireDate, passportCountry } = identification || {};
	const [Loading, setLoading] = useState(false);
	const [EditPassport, setEditPassport] = useState();
	const [Values, setValues] = useState({
		passportNumber,
		passportIssueDate,
		passportExpireDate,
		passportCountry,
	});

	useEffect(() => {
		if (passportNumber) {
			setValues({
				passportNumber,
				passportIssueDate,
				passportExpireDate,
				passportCountry,
			});
		}
	}, [passportNumber]);

	function validatePassportDates(expiry, issue) {
		const currentDate = new Date();
		const expireMonth = parseInt(expiry.slice(0, 2));
		const expireYear = parseInt(expiry.slice(2, 4)) + 2000;
		const issueMonth = parseInt(issue.slice(0, 2));
		const issueYear = parseInt(issue.slice(2, 4)) + 2000;

		if (expireMonth > 12 || issueMonth > 12) {
			return false;
		}
		// Subtract 1 from the month since months are 0-indexed
		const expireDate = new Date(expireYear, expireMonth - 1, 1);
		const issueDate = new Date(issueYear, issueMonth - 1, 1);
		if (expireDate > currentDate && issueDate < currentDate) {
			return true;
		} else {
			return false;
		}
	}

	const onUpdate = () => {
		if (!Values.passportNumber || !Values.passportIssueDate || !Values.passportExpireDate) {
			return Toast.error("All the number fields are required");
		}
		if (!Values.passportCountry) {
			return Toast.error("Please select passport country");
		}

		if (!validatePassportDates(Values.passportExpireDate, Values.passportIssueDate)) {
			return Toast.error("Please enter valid number issue and expire date");
		}
		setLoading(true);
		patch(
			userId ? apis.updateUserIdentification(userId) : guestId ? apis.updateGuestIdentification(guestId) : apis.updateMyIdentification,
			{
				...Values,
				passportIssueDate: Values.passportIssueDate,
				passportExpireDate: Values.passportExpireDate,
				...(guestId ? { guestId } : { userId }),
			},
			(r, e) => {
				setLoading(false);
				if (r) {
					reload(Values);
					Toast.success(`Passport Updated`);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	const newChanges = passportNumber !== Values.passportNumber || passportIssueDate !== Values.passportIssueDate || passportExpireDate !== Values.passportExpireDate || passportCountry !== Values.passportCountry;

	return (
		<>
			<Button onClick={() => setEditPassport(true)} variant="outlined" className="btn-xs">
				<TbBook className="mr-2" />
				{passportNumber || "Add Passport"}
			</Button>
			<Modal open={EditPassport} onClose={() => setEditPassport()} contentClass="!max-w-[35rem]">
				<div className="p-10">
					<div className="relative gap-10 align-center">
						<Avatar photo={photo} className="w-40 h-40" />
						<div className="flex-grow">
							<h1>Passport (IN)</h1>
							<Form onSubmit={onUpdate} className="grid grid-cols-2 gap-4 mt-4">
								<Input required value={Values.passportNumber} label="Passport Number" placeholder="Passport Number" onChange={(passportNumber) => setValues((prev) => ({ ...prev, passportNumber }))} />
								<Select btnClass="w-full" options={COUNTRIES} required selected={{ value: Values.passportCountry, label: Values.passportCountry }} label="Country" placeholder="Country" onChange={(country) => setValues((prev) => ({ ...prev, passportCountry: country.value }))} />
								<Input maxLength={4} required value={Values.passportIssueDate} label="Issue Date" placeholder="MMYY" onChange={(passportIssueDate) => setValues((prev) => ({ ...prev, passportIssueDate }))} />
								<Input maxLength={4} required value={Values.passportExpireDate} label="Expire Date" placeholder="MMYY" onChange={(passportExpireDate) => setValues((prev) => ({ ...prev, passportExpireDate }))} />
								{newChanges && (
									<Button loading={Loading} type="submit" className="col-span-2">
										Save
									</Button>
								)}
							</Form>
							<img src={Stamp} alt="stamp" className="absolute w-40 -left-6 -bottom-4" />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default EditPassport;

import React from "react";
import { Attachments, KeyValue, Violations, Toast, DatePicker, EditableInput, EditableSelect, Remarks } from "../..";
import StatusIndicator from "../Components/status";
import { apis, patch, post } from "../../../Utils/axios";
import moment from "moment";
import useFetch from "../../../Hooks/useFetch";
import NextAction from "./nextAction";
import { useClientSettings } from "Hooks/useClientSettings";

function EditableReceipt({ receipt, onReload = () => {} }) {
	const { currency } = useClientSettings();
	const { response } = useFetch(apis.getExpenseCategories, null, { categories: [] });
	const { id, date, amount, category, status, description, violations, remarks } = receipt;

	const onUpdate = (obj) => {
		patch(`/receipts/${id}`, obj, (r, e) => {
			if (r) {
				Toast.success(`Receipt Updated`);
				onReload();
			} else if (e) {
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<div className="p-4 rounded-t-lg shadow-sm bg-canvas">
				<h1>Expense</h1>
				<div className="mt-6">
					<h4>Receipt Details</h4>
					<div className="flex flex-col mt-4 gap-y-2">
						<Violations className="mb-2" violations={violations} />
						<KeyValue label="Receipt ID">{id}</KeyValue>
						<KeyValue label="Date">
							<DatePicker btnClass="!py-1 !text-xs max-w-min" date={date} setDate={(date) => onUpdate({ date })} backDateSelection maxDate={moment().valueOf()} />
						</KeyValue>
						<KeyValue label="Amount">
							<EditableInput required onUpdate={(amount) => onUpdate({ amount })} value={amount} label="Amount" /> {currency} {amount}
						</KeyValue>
						<KeyValue label="Category">
							<EditableSelect options={response.categories.map((c) => ({ value: c.id, label: c.name }))} onChange={(category) => onUpdate({ categoryId: category.value })} selected={{ label: category?.name || "None", value: category?.id }} />
						</KeyValue>
						<KeyValue label="Purpose">
							<EditableInput required onUpdate={(description) => onUpdate({ description })} value={description} label="Description" />
							{description}
						</KeyValue>
						<KeyValue label="Status">
							<StatusIndicator status={status.toLowerCase()} />
						</KeyValue>
					</div>
					{receipt.attachments?.length > 0 && (
						<>
							<h4 className="mt-4">Attachments</h4>
							<Attachments className="mt-4" files={receipt.attachments} />
						</>
					)}
					<Remarks remarks={remarks} className="mt-4" />
				</div>
			</div>
			<div className="rotate-180 zigzag" />
			<NextAction status={status} />
		</>
	);
}

export default EditableReceipt;

import React, { useEffect, useState } from "react";
import { BottomBar, ButtonGroup, CardsRenderer, CardsWrapper, Booking, ImportBooking, DateRange, Pagination, Searchbox, Toast } from "../../../../../../Components";
import apis from "../../../../../../Utils/apis";
import { get } from "../../../../../../Utils/axios";
import moment from "moment";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";

function FlightBookings() {
	const { isEnabled } = useClientSettings();
	const [BookingStatus, setBookingStatus] = useState({ label: "Booked", value: "upcoming" });
	const initial = {
		bookings: [],
		count: 0,
		pages: 0,
	};
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(30, "days").valueOf(),
			endDate: moment().add(30, "days").valueOf(),
		},
	});
	const [Bookings, setBookings] = useState(initial);
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		fetchBookings(BookingStatus.value, Query);
	}, [BookingStatus, Query]);

	const fetchBookings = (type, query) => {
		setLoading(true);
		let url;
		if (type === "pending") {
			url = apis.getPendingFlightBookings;
		} else if (type === "upcoming") {
			url = apis.getUpcomingFlightBookings;
		} else if (type === "archived") {
			url = apis.getArchivedFlightBookings;
		}
		get(
			url,
			{
				...query,
				dates: {
					startDate: moment(query.dates.startDate).toDate(),
					endDate: moment(query.dates.endDate).toDate(),
				},
			},
			(r, e) => {
				if (r) {
					setBookings(r);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<>
			<BottomBar>
				<div className="w-full flex-center-between">
					<div className="gap-2 align-center">
						<ImportBooking type="flight" onReload={() => fetchBookings(BookingStatus.value, Query)} />
						<h5>{Bookings.count} Flight Bookings</h5>
					</div>
					<div>
						<Pagination active={Query.page} total={Bookings.pages} onChange={(page) => setQuery({ ...Query, page })} />
					</div>
				</div>
			</BottomBar>
			<div className="gap-2 mt-6 flex-center-between">
				<div className="align-center">
					<ButtonGroup onClick={setBookingStatus} size="xs !p-1.5" selected={BookingStatus} className="text-sm" options={[{ label: "Upcoming", value: "upcoming" }, ...(isEnabled("ORGANIZATION") ? [{ label: "Pending", value: "pending" }] : []), { label: "Archived", value: "archived" }]} />
				</div>
				<div className="align-center gap-x-2">
					<Searchbox placeholder="Search bookings" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
					<DateRange setRange={(dates) => setQuery({ ...Query, dates })} range={Query.dates} inputClass="!p-1.5 !text-xs" />
				</div>
			</div>
			<CardsWrapper loading={Loading}>
				<CardsRenderer
					items={Bookings.bookings}
					render={(booking, i) => {
						return <Booking key={i} booking={booking} type="flight" onReload={() => fetchBookings(BookingStatus.value, Query)} />;
					}}
				/>
			</CardsWrapper>
		</>
	);
}

export default FlightBookings;

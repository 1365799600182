import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import NotFound from "./NotFound";
import Verify from "./Signup/verify";
import App from "./App";
import { useLoginHook } from "../Hooks/useLoginHook";
import ForgotPassword from "./Login/forgotPassword";
// import useTheme from "../Hooks/useTheme";
import BlankPage from "./blank";
import UserOnboard from "./App/Wizards/User/onboard";
import JoinTeam from "./App/Wizards/User/joinTeam";
import MobileStore from "./MobileStore";
import LoadingScreen from "./App/loading";
import Authenticate from "./Login/authenticate";
import OnboardWizard from "./App/Wizards/Admin";
import ActivateAccount from "./Signup/activate";
const MailRoutes = lazy(() => import("./Mail"));

function Pages() {
	const { authenticated } = useLoginHook();

	return (
		<>
			<Routes>
				<Route
					path="/mail/*"
					element={
						<Suspense fallback={<LoadingScreen />}>
							<MailRoutes />
						</Suspense>
					}
				/>
				<Route path="/store" element={<MobileStore />} />
				<Route path="/join" element={<JoinTeam />} />
				<Route path="/onboard" element={<UserOnboard />} />
				<Route path="/activate" element={<ActivateAccount />} />
				<Route path="/verify" element={<Verify />} />
				<Route path="/blank" element={<BlankPage />} />
				<Route path="/authenticate" element={<Authenticate />} />

				{authenticated ? (
					<>
						<Route path="/app/*" element={<App />} />
						<Route path="/*" element={<App />} />
					</>
				) : (
					<>
						<Route path="/wiz/*" element={<OnboardWizard />} />
						<Route path="/reset" element={<ForgotPassword />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/login" element={<Login />} />
						<Route path="/*" element={<Login />} />
					</>
				)}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</>
	);
}

export default Pages;

import React, { useState } from "react";
import { Form, Input, Button, UploadPhoto, DatePicker, Toast, Logo, ButtonGroup, Select } from "../../../../Components";
import moment from "moment";
import { FiArrowRight } from "react-icons/fi";
import { apis, post } from "../../../../Utils/axios";
import HappyDog from "../../../../Assets/Images/happy_dog.jpg";
import { isPasswordStrong, validatePhoneNumber } from "../../../../Utils/utils";
import { useLoginHook } from "../../../../Hooks/useLoginHook";
import { COUNTRIES } from "Utils/constants";

function Onboard() {
	const Params = new URLSearchParams(window.location.search);
	const code = Params.get("code");

	const { logout } = useLoginHook();
	const [SetupDone, setSetupDone] = useState();
	const [Loader, setLoader] = useState();
	const [Values, setValues] = useState({
		fName: null,
		lName: null,
		gender: {},
		photo: null,
		phone: "",
		country: {},
		dob: moment().subtract(18, "years").valueOf(),
	});

	const onSubmit = async () => {
		if (!Values.gender?.value) {
			return Toast.error("Please select gender");
		}
		if (Values.password !== Values.confirm) {
			Toast.error("Passwords do not match");
			return;
		}
		if (!isPasswordStrong(Values.password)) {
			Toast.error("Password must be 6 characters long with one special character and one uppercase letter and one number");
			return;
		}
		setLoader(true);
		if (!(await validatePhoneNumber(Values.phone, Values.country.value))) {
			setLoader();
			return Toast.error("Please enter a valid phone number");
		}
		post(
			apis.onboardNewUser,
			{
				...Values,
				dob: moment(Values.dob).toDate(),
				gender: Values.gender?.value,
				code,
			},
			(r, e) => {
				if (r) {
					setSetupDone(true);
					setLoader();
				} else if (e) {
					Toast.handleError(e);
					setLoader();
				}
			}
		);
	};

	if (SetupDone) {
		return (
			<div className="justify-center w-full h-full align-center">
				<div className="w-full max-w-md p-4 text-center">
					<img src={HappyDog} alt="Happy dog" className="max-w-xs m-auto mb-8 rounded-full" />
					<Logo className="justify-center" />
					<div className="mt-6 text-5xl font-bold">Account Ready</div>
					<div className="mt-3 text-sm">Your password has been successfully activated, you can now sign in</div>
					<Button onClick={logout} className="m-auto mt-8">
						Sign in to your account
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="justify-center w-full h-full align-center">
				<div className="w-full max-w-sm p-4 text-center">
					<div className="mt-6 text-5xl font-bold">We are thrilled</div>
					<div className="mt-3 text-sm">Please enter your profile details to setup your account</div>

					<div className="flex flex-col mt-10 text-start">
						<Form onSubmit={onSubmit} loading={Loader}>
							<UploadPhoto className="m-auto mb-8" photo={Values.photo} onUpdate={(obj) => setValues((prev) => ({ ...prev, photo: obj.file.url }))} />
							<div className="grid gap-4 mb-4 lg:grid-cols-2">
								<Input required value={Values.fName} onChange={(fName) => setValues((prev) => ({ ...prev, fName }))} label="First Name" placeholder="First Name" />
								<Input required value={Values.lName} onChange={(lName) => setValues((prev) => ({ ...prev, lName }))} label="Last Name" placeholder="Last Name" />
							</div>
							<div className="grid gap-4 mb-4 lg:grid-cols-2">
								<Input type="password" value={Values.password} onChange={(password) => setValues((prev) => ({ ...prev, password }))} required label="New Password" placeholder="Password" />
								<Input type="password" value={Values.confirm} onChange={(confirm) => setValues((prev) => ({ ...prev, confirm }))} required label="Confirm New Password" placeholder="Confirm Password" />
							</div>
							<div className="grid gap-4 mb-4 lg:grid-cols-2">
								<div>
									<label className="block mb-2">Gender</label>
									<ButtonGroup
										selected={Values.gender}
										onClick={(gender) => setValues((prev) => ({ ...prev, gender }))}
										options={[
											{
												label: "Male",
												value: "MALE",
											},
											{
												label: "Female",
												value: "FEMALE",
											},
										]}
									/>
								</div>
								<DatePicker backDateSelection={true} maxDate={moment().subtract(18, "years").valueOf()} date={Values.dob} setDate={(dob) => setValues((prev) => ({ ...prev, dob }))} label="Date of Birth" />
							</div>
							<label>Phone Number</label>
							<div className="flex gap-2">
								<Select className="max-w-min" placeholder="Code" selected={Values.country} onChange={(country) => setValues((prev) => ({ ...prev, country }))} required label="" options={COUNTRIES} />
								<Input className="flex-1" type="number" required value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} placeholder="Phone" />
							</div>
							<Button variant="primary" loading={Loader} type="submit" className="w-full mt-8">
								Go to your Account <FiArrowRight className="ml-2" />
							</Button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Onboard;

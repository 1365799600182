import React, { useState } from "react";
import { apis, patch } from "../../Utils/axios";
import { Button, Form, Input, Modal, Select, Toast } from "..";
import { COUNTRIES } from "../../Utils/constants";
import { TbIdBadge } from "react-icons/tb";

function EditNationalId({ reload, identification = {}, userId, guestId }) {
	const [Loading, setLoading] = useState(false);
	const { nationality, nationalIdNumber } = identification || {};
	const [EditPassport, setEditPassport] = useState();
	const [Values, setValues] = useState({
		nationality,
		nationalIdNumber,
	});

	const onUpdate = () => {
		if (!Values.nationalIdNumber || !Values.nationality) {
			return Toast.error("Please fill all the fields");
		}
		setLoading(true);
		patch(
			userId ? apis.updateUserIdentification(userId) : guestId ? apis.updateGuestIdentification(guestId) : apis.updateMyIdentification,
			{
				...Values,
				...(guestId ? { guestId } : { userId }),
			},
			(r, e) => {
				setLoading(false);
				if (r) {
					reload(Values);
					Toast.success(`Identification Updated`);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	const newChanges = nationalIdNumber !== Values.nationalIdNumber || nationality !== Values.nationality;

	return (
		<>
			<Button onClick={() => setEditPassport(true)} variant="outlined" className="btn-xs">
				<TbIdBadge className="mr-2" />
				{nationalIdNumber || "Add National ID"}
			</Button>
			<Modal open={EditPassport} onClose={() => setEditPassport()} contentClass="!max-w-[35rem]">
				<div className="p-6">
					<h1>National ID</h1>
					<Form onSubmit={onUpdate} className="grid grid-cols-2 gap-4 mt-4">
						<Input required value={Values.nationalIdNumber} label="National ID" placeholder="National ID" onChange={(nationalIdNumber) => setValues((prev) => ({ ...prev, nationalIdNumber }))} />
						<Select btnClass="w-full" options={COUNTRIES} required selected={{ value: Values.nationality, label: Values.nationality }} label="Nationality" placeholder="Nationality" onChange={(nationality) => setValues((prev) => ({ ...prev, nationality: nationality.value }))} />
						{newChanges && (
							<Button loading={Loading} type="submit" className="col-span-2">
								Save
							</Button>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default EditNationalId;

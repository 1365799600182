import React, { useEffect } from "react";
import { Badge, Button, EditableInput, LabelValue, Toast, TravellerSearch, Avatar } from "../../../../../../../Components";
import { useClientSettings } from "../../../../../../../Hooks/useClientSettings";
import useUserProfile from "../../../../../../../Hooks/useUserProfile";
import { validatePhoneNumber } from "../../../../../../../Utils/utils";
import apis from "../../../../../../../Utils/apis";
import { get, patch } from "../../../../../../../Utils/axios";
import { FiMail } from "react-icons/fi";
// import AddGST from "./addGst";
import { useUserConfig } from "../../../../../../../Hooks/useUserConfig";
import { FaTrashAlt } from "react-icons/fa";

function Travellers({ max, passengers = {}, setPassengers = () => {}, requestId }) {
	const { isEnabled } = useClientSettings();
	const { checkUserPermission } = useUserConfig();
	const allowTravellersSelection = isEnabled("ORGANIZATION") && checkUserPermission("TA");
	const { profile, reload } = useUserProfile();
	const { id, fName, lName, phone, email, photo } = profile;

	useEffect(() => {
		if (allowTravellersSelection) {
			setPassengers([]);
		} else {
			setPassengers([profile]);
		}
	}, [allowTravellersSelection, profile]);

	useEffect(() => {
		//If booking is done against a request, automatically add the requestor in travellers
		if (requestId) {
			fetchRequestDetails(requestId);
		}
	}, [requestId]);

	const fetchRequestDetails = (requestId) => {
		get(apis.getRequestorDetails, { requestId }, (r, e) => {
			if (r) {
				addTraveller(r);
			}
		});
	};

	const addTraveller = (traveller) => {
		//Check if traveller already exists
		const index = passengers.findIndex((p) => p.email === traveller.email);
		if (index >= 0) {
			return Toast.error("You have already selected this traveller once");
		}
		setPassengers((prev) => [...prev, traveller]);
	};

	const removeTraveller = (email) => {
		setPassengers((prev) => prev.filter((p) => p.email !== email));
	};

	if (allowTravellersSelection) {
		return (
			<>
				{passengers.length < max && <TravellerSearch onSelect={addTraveller} selected={passengers} />}
				{requestId && passengers.length === max && <Badge variant="warning">Travellers were automatically added from the request</Badge>}
				{passengers.map((traveller, i) => {
					const { fName, lName, email, phone } = traveller;

					return (
						<div key={i} className="mt-4 border rounded-md fade-in-up">
							<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
								<div className="flex-grow gap-2 align-center">
									<h5>
										{fName} {lName}
									</h5>
								</div>
								<div className="gap-2 text-label align-center">
									<FiMail />
									{email}
									<Button onClick={() => removeTraveller(email)} variant="danger" className="btn-xs">
										<FaTrashAlt className="mr-2" />
										Remove
									</Button>
								</div>
							</div>
							<div className="grid grid-cols-3 gap-4 p-4">
								<LabelValue label="Phone">{phone}</LabelValue>
							</div>
						</div>
					);
				})}
			</>
		);
	}

	const onUpdate = async (obj) => {
		if (obj.phone && !(await validatePhoneNumber(obj.phone))) {
			return Toast.error("Please enter valid phone number");
		}
		patch(apis.updateMyProfile, obj, (r, e) => {
			if (r) {
				reload();
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	return (
		<>
			<div className="border rounded-md fade-in-up">
				<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
					<div className="flex-grow gap-2 align-center">
						<Avatar photo={photo} className="w-6 h-6" />
						<h5>
							{fName} {lName}
						</h5>
					</div>
					<div className="gap-2 text-label align-center">
						<FiMail />
						{email}
					</div>
				</div>
				<div className="grid grid-cols-3 gap-4 p-4">
					<LabelValue label="Phone">
						<EditableInput type="number" required onUpdate={(phone) => onUpdate({ phone })} value={phone} label="Phone Number" />
						{phone}
					</LabelValue>
				</div>
			</div>
		</>
	);
}

export default Travellers;

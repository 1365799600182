import React from "react";
import { Badge } from "../..";

function StatusIndicator({ status }) {
	if (status === "draft") {
		return <Badge>With Me</Badge>;
	}
	if (status === "inPolicy") {
		return <Badge variant="success">In Policy</Badge>;
	}
	if (status === "needsApproval") {
		return <Badge variant="warning">Needs Approval</Badge>;
	}
	if (status === "outOfPolicy") {
		return <Badge variant="danger">Out of Policy</Badge>;
	}
	if (status === "pending") {
		return <Badge variant="warning">Pending</Badge>;
	}
	if (status === "approval") {
		return <Badge variant="warning">Approval</Badge>;
	}
	if (status === "approved") {
		return <Badge variant="success">Approved</Badge>;
	}
	if (status === "paid") {
		return <Badge variant="success">Reimbursed</Badge>;
	}
	if (status === "denied") {
		return <Badge variant="danger">Denied</Badge>;
	}

	return null;
}

export default StatusIndicator;

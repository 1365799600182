import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import useFetch from "../../../../Hooks/useFetch";
import apis from "../../../../Utils/apis";

function AtAGlance() {
	const { response } = useFetch(apis.atAGlanceCount, null, {
		count: {
			approvalsToday: 0,
			totalPendingApprovals: 0,
			readyToBook: 0,
			awaitingApproval: 0,
			pendingWithTravelHead: 0,
		},
	});

	const { approvalsToday, totalPendingApprovals, readyToBook, awaitingApproval, pendingWithTravelHead } = response.count;

	return (
		<>
			<div className="col-span-2 mt-6 rounded-2xl">
				<h3>At a Glance</h3>
				<div className="grid grid-cols-5 gap-4 mt-4 text-dark">
					<div className="flex flex-col justify-between p-4 text-white bg-accent rounded-xl">
						<h1 className="text-4xl">{approvalsToday}</h1>
						<div className="mt-2 text-sm">Approvals Arrived Today</div>
					</div>
					<div className="flex flex-col justify-between p-4 border bg-whitish rounded-xl">
						<h1 className="text-4xl flex-center-between">
							{totalPendingApprovals}
							<div className="w-8 h-8 text-lg rounded-full bg-surface flex-center">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="mt-2 text-sm">Total Pending Approvals</div>
					</div>
					<div className="flex flex-col justify-between p-4 border bg-whitish rounded-xl">
						<h1 className="text-4xl flex-center-between">
							{readyToBook}
							<div className="w-8 h-8 text-lg rounded-full bg-surface flex-center">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="mt-2 text-sm">Approvals ready to book</div>
					</div>
					<div className="flex flex-col justify-between p-4 border bg-whitish rounded-xl">
						<h1 className="text-4xl flex-center-between">
							{awaitingApproval}
							<div className="w-8 h-8 text-lg rounded-full bg-surface flex-center">
								<FiArrowUpRight />
							</div>
						</h1>
						<div className="mt-2 text-sm">Requested Approvals</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AtAGlance;

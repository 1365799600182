import { Popover, Transition } from "@headlessui/react";
import React from "react";
import { useState } from "react";
import { FiUsers } from "react-icons/fi";
import { usePopper } from "react-popper";
import { IncrementDecrement } from "../..";
import { FaBabyCarriage, FaChild, FaUser } from "react-icons/fa";

function Selectpassengers({ className = "", label, onChange = () => {}, passengers = {} }) {
	const MAX = 9;
	const totalPassengers = Object.values(passengers).reduce((a, b) => a + b) - passengers.infant; //infant is not a passenger

	//Popper stuff
	const [PopRef, setPopRef] = useState(null);
	const [PopElem, setPopElem] = useState(null);
	const { styles, attributes } = usePopper(PopRef, PopElem, {
		placement: "bottom-start",
	});

	return (
		<>
			<Popover className={`relative popup ${className}`}>
				{({ open }) => (
					<>
						{label && <label className="block mb-2">{label}</label>}
						<Popover.Button ref={setPopRef} className={`w-full`}>
							<div ref={setPopRef} className="text-sm ring-1 ring-inset ring-muted bg-canvas shadow-sm rounded-md p-2.5 align-center cursor-pointer">
								<FiUsers className="mr-2" />
								<div className="whitespace-nowrap">{totalPassengers} Travellers</div>
							</div>
						</Popover.Button>
						<Transition ref={setPopElem} style={styles.popper} {...attributes.popper} className="z-10">
							<Popover.Panel className={`content !max-w-xs`}>
								<div className="flex flex-col p-4 gap-y-4">
									<div className="flex-center-between">
										<div>
											<div className="text-sm font-medium align-center gap-x-2">
												<FaUser /> Adult
											</div>
											<div className="text-label">Above 12 Years</div>
										</div>
										<IncrementDecrement max={MAX - totalPassengers + passengers.adult} count={passengers.adult} onChange={(val) => onChange({ adult: val })} />
									</div>
									<div className="flex-center-between">
										<div>
											<div className="text-sm font-medium align-center gap-x-2">
												<FaChild />
												Child
											</div>
											<div className="text-label">2 - 12 Years</div>
										</div>
										<IncrementDecrement max={MAX - totalPassengers + passengers.child} count={passengers.child} onChange={(val) => onChange({ child: val })} />
									</div>
									<div className="flex-center-between">
										<div>
											<div className="text-sm font-medium align-center gap-x-2">
												<FaBabyCarriage />
												Infant
											</div>
											<div className="text-label">Below 2 Years</div>
										</div>
										<IncrementDecrement max={passengers.adult} count={passengers.infant} onChange={(val) => onChange({ infant: val })} />
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</>
	);
}

export default Selectpassengers;

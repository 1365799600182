import React, { useState } from "react";
import { FaArrowRight, FaCheck, FaCheckCircle } from "react-icons/fa";
import { Toast, Badge, Button } from "Components";

function SubscriptionPlans({ loading = false, onSubmit = () => {} }) {
	const [SelectedPlan, setSelectedPlan] = useState();

	const handleSubmit = () => {
		if (!SelectedPlan) {
			Toast.error("Please select a plan");
			return;
		}
		onSubmit(SelectedPlan);
	};

	return (
		<div className="flex flex-col items-center justify-center h-full text-center gap-y-10 fade-in-right">
			<div>
				<h1 className="text-4xl font-bold">Choose your plan</h1>
				<div className="mt-2 text-xl font-thin">We will not charge you anything for now, you can cancel anytime</div>
			</div>
			<div className="grid max-w-6xl grid-cols-3 mx-auto mt-8 gap-x-4">
				<div onClick={() => setSelectedPlan("BASIC")} className={`border rounded-xl p-6 flex-grow hover:bg-light cursor-pointer ${SelectedPlan === "BASIC" ? "ring-2 ring-primary" : ""}`}>
					<h1 className="text-3xl">Basic</h1>
					<h1 className="mt-2 text-lg">USD 29 / month</h1>
					<p className="text-sm text-secondary">+ USD 2 per booking</p>
					<Badge variant="default" className="mt-2">
						Small Teams
					</Badge>
					<ul className="flex flex-col mt-6 text-sm text-start text-secondary gap-y-2">
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Create organization and invite users into teams
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel Policies and Approvals
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel admin desks
						</li>
					</ul>
				</div>
				<div onClick={() => setSelectedPlan("PREMIUM")} className={`border rounded-xl p-6 flex-grow hover:bg-light cursor-pointer ${SelectedPlan === "PREMIUM" ? "ring-2 ring-primary" : ""}`}>
					<h1 className="text-3xl">Premium</h1>
					<h1 className="mt-2 text-lg">USD 39 / month</h1>
					<p className="text-sm text-secondary">+ USD 1 per booking</p>
					<Badge variant="success" className="mt-2">
						Mid Sized Teams
					</Badge>
					<ul className="flex flex-col mt-6 text-sm text-start text-secondary gap-y-2">
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Create organization and invite users into teams
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel Policies and Approvals
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel admin desks
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Bookings for guests
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Expense Policies and Approvals
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Receipt store for filling reimbursements on the go
						</li>
					</ul>
				</div>
				<div onClick={() => setSelectedPlan("ENTERPRISE")} className={`relative border rounded-xl p-6 flex-grow hover:bg-light cursor-pointer ${SelectedPlan === "ENTERPRISE" ? "ring-2 ring-primary" : ""}`}>
					<h1 className="text-3xl">Enterprise</h1>
					<h1 className="mt-2 text-lg">Contact Sales</h1>
					<Badge variant="info" className="mt-2">
						Large Teams
					</Badge>
					<ul className="flex flex-col mt-6 text-sm text-start text-secondary gap-y-2">
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Create organization and invite users into teams
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel Policies and Approvals
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Travel admin desks
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Bookings for guests
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Expense Policies and Approvals
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Receipt store for filling reimbursements on the go
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Custom fields for additional data collection
						</li>
						<li className="flex">
							<FaCheck className="mt-1 mr-2" />
							Customized reports
						</li>
					</ul>
					{SelectedPlan === "ENTERPRISE" && <FaCheckCircle className="absolute w-6 h-6 top-4 left-4 text-primary" />}
				</div>
			</div>
			<Button loading={loading} onClick={handleSubmit} variant="primary" className="mx-auto mt-8">
				Verify your email <FaArrowRight className="ml-2" />
			</Button>
		</div>
	);
}

export default SubscriptionPlans;

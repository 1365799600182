import React, { useState } from "react";
import { Button, Modal, Toast } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "./itinerary";
import { useUserPolicy } from "../../../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import SendToAdmin from "./sendToAdmin";
import { apis, post } from "../../../../../../../../Utils/axios";
import FareRules from "./fareRules";
import FlightDetails from "./details";
import moment from "moment";
import Thinking from "../../../../../../../../Assets/Images/emojis/thinking.png";

function Summary({ selectedFlight, passengers, approvalId, approval, requestId, fromCode, toCode, date, adult, child, infant, cabinClass }) {
	const { checkUserPermission } = useUserConfig();
	const isTA = checkUserPermission("TA");
	const { travelPolicy } = useUserPolicy();
	const approvedAmount = approval?.amount;
	const adminBooking = travelPolicy?.domesticFlight?.adminBooking && !isTA;
	const askApproval = !selectedFlight?.selectedFare?.inPolicy && !isTA && (approvedAmount ? selectedFlight?.selectedFare?.price > Number(approvedAmount) : true);
	const violations = [...selectedFlight?.selectedFare?.violations];
	const { segments } = selectedFlight;
	const departure = segments[0];
	const NAVIGATE = useNavigate();
	const [Loading, setLoading] = useState();
	const [PriceChangeConfirm, setPriceChangeConfirm] = useState(false);

	const confirmFare = () => {
		setLoading(true);
		post(
			apis.confirmFlightFare,
			{
				from: fromCode,
				to: toCode,
				date: moment(date).format("DD-MMM-YYYY"),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: selectedFlight?.selectedFare?.fareKey,
				flightSearchKey: selectedFlight?.sid,
			},
			(r, e) => {
				setLoading(false);
				if (r?.statusMessage === "success") {
					if (r.priceDiffernce > 0) {
						setPriceChangeConfirm(r);
						return;
					} else {
						moveNext(r);
					}
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	const moveNext = (data) => {
		NAVIGATE(`/app/travel/flights/book`, {
			state: {
				fareConfirmDetails: data,
				flight: selectedFlight,
				flightOptionKey: data.selectedFlightOptionKey,
				flightSearchKey: selectedFlight?.sid,
				passengers,
				approvalId,
				requestId,
			},
		});
	};

	if (PriceChangeConfirm) {
		return (
			<Modal open={true}>
				<div className="p-6 text-center border rounded-md">
					<img src={Thinking} alt="Eyes Emoji" className="w-20 m-auto mb-6" />
					<h1>Fare changed by Airline</h1>
					<div className="mt-2 text-sm">The fare for your booking has been changed. You can either continue with the new price or go back to search for a better deal.</div>
					<div className="flex items-center justify-center gap-2">
						<Button onClick={() => moveNext(PriceChangeConfirm)} variant="dark" className="btn-sm">
							Continue with new price
						</Button>
						<Button onClick={() => window.location.reload()} variant="dark" className="btn-sm">
							Refresh Options
						</Button>
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<div className="flex flex-col justify-between w-full h-full fade-in-right">
			<div className="flex flex-col gap-6 p-4 overflow-y-scroll">
				<div className="align-center">
					<img src={departure.logo} alt="airline-logo" className="w-10 h-10 mr-4 rounded-full" />
					<h3>{departure.airlineName}</h3>
				</div>
				<div>
					<FlightItinerary selectedFlight={selectedFlight} />
				</div>
				<div>
					<FlightDetails baggage={{ checkin: selectedFlight?.segments[0].checkinBaggage, cabin: selectedFlight?.segments[0].cabinBaggage }} />
				</div>
				<div>
					<FareRules {...{ refundable: selectedFlight?.selectedFare?.refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey: selectedFlight?.selectedFare?.fareKey, flightSearchKey: selectedFlight?.sid }} />
				</div>
			</div>
			<div className="p-4">
				<div>
					<div className="gap-1 mb-2 text-sm align-center">
						For {passengers.adult} Adult
						{passengers.child > 0 && <>, {passengers.child} Child</>}
						{passengers.infant > 0 && <>, {passengers.infant} Infant</>}
					</div>
					<h1>
						Total {selectedFlight?.selectedFare?.currency} {selectedFlight?.selectedFare?.price}
					</h1>
				</div>
				<div className="mt-4">
					{askApproval ? (
						<AskApproval {...{ flight: selectedFlight, fare: selectedFlight?.selectedFare, type: "oneway", violations }} />
					) : adminBooking ? (
						<SendToAdmin {...{ flight: selectedFlight, fare: selectedFlight?.selectedFare, type: "oneway", approvalId }} />
					) : (
						<Button loading={Loading} variant="primary" onClick={confirmFare} className="w-full">
							Continue
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default Summary;

import React, { useEffect, useState } from "react";
import { BottomBar, Button, ButtonGroup, CardsRenderer, DateRange, Pagination, Receipt, Searchbox, Table, TableCardRender, TableSwitch, Toast } from "../../../../../Components";
import ExpenseFilters from "./filters";
import { FiEye } from "react-icons/fi";
import moment from "moment";
import apis from "../../../../../Utils/apis";
import { get } from "../../../../../Utils/axios";
import ViewReceipt from "../../../../../Components/Receipt/ViewReceipt";
import { StampToDate } from "../../../../../Utils/utils";
import StatusIndicator from "../../../../../Components/Receipt/Components/status";
import SubmitReceipt from "./SubmitReceipt";
import AddReceipt from "./AddReceipt";
import { useClientSettings } from "../../../../../Hooks/useClientSettings";

function WithMe() {
	const { isEnabled } = useClientSettings();
	const expenseEnabled = isEnabled("ORGANIZATION") && isEnabled("EXPENSE");

	const [ViewMode, setViewMode] = useState();
	const [OpenId, setOpenId] = useState();
	const [Dragging, setDragging] = useState();

	const [ReceiptStatus, setReceiptStatus] = useState({ label: "With Me", value: "withMe" });
	const initial = {
		receipts: [],
		count: 0,
		pages: 0,
	};
	const [Query, setQuery] = useState({
		search: "",
		page: 1,
		dates: {
			startDate: moment().subtract(30, "days").valueOf(),
			endDate: moment().valueOf(),
		},
	});

	const [Receipts, setReceipts] = useState(initial);
	const [Loading, setLoading] = useState(true);

	useEffect(() => {
		fetchReceipts(ReceiptStatus.value, Query);
	}, [ReceiptStatus, Query]);

	const fetchReceipts = (type, query) => {
		setLoading(true);
		let url;
		if (type === "withMe") {
			url = apis.getWithMeReceipts;
		} else if (type === "submitted") {
			url = apis.getPendingReceipts;
		} else if (type === "completed") {
			url = apis.getCompletedReceipts;
		}

		get(
			url,
			{
				...query,
				dates: {
					startDate: moment(query.dates.startDate).toDate(),
					endDate: moment(query.dates.endDate).toDate(),
				},
			},
			(r, e) => {
				if (r) {
					setReceipts(r);
					setLoading(false);
				} else if (e) {
					Toast.handleError(e);
					setLoading(false);
				}
			}
		);
	};

	return (
		<>
			{ReceiptStatus.value === "withMe" && <SubmitReceipt loading={Loading} dragging={Dragging} onReload={() => fetchReceipts(ReceiptStatus.value, Query)} />}
			<div className="p-4 pb-24">
				<div className="gap-4 flex-center-between">
					{expenseEnabled && (
						<ButtonGroup
							onClick={setReceiptStatus}
							size="xs !p-1.5"
							selected={ReceiptStatus}
							className="text-sm"
							options={[
								{ label: "With Me", value: "withMe" },
								{ label: "Submitted", value: "submitted" },
								{ label: "Completed", value: "completed" },
							]}
						/>
					)}
					<div className="gap-2 align-center">
						<Searchbox placeholder="Search receipts" cmd="s" onChange={(search) => setQuery({ ...Query, search })} />
						{ReceiptStatus.value === "completed" && <DateRange maxDate={moment().startOf("date").valueOf()} setRange={(dates) => setQuery({ ...Query, dates })} range={Query.dates} inputClass="!p-1.5 !text-xs" />}
						<TableSwitch mode={ViewMode} setMode={setViewMode} />
						{/* <ExpenseFilters /> */}
					</div>
				</div>
				<TableCardRender
					loading={Loading}
					mode={ViewMode}
					table={
						<Table
							loading={Loading}
							className="mt-4 border rounded-md"
							nodes={Receipts.receipts.map(({ id, merchant, category, amount, date, createdAt, status }) => ({
								id,
								merchant,
								category: category?.name,
								amount,
								date: StampToDate(date),
								createdAt: StampToDate(createdAt),
								status: <StatusIndicator status={status} id={id} />,
								actions: (
									<Button onClick={() => setOpenId(id)} variant="outlined" className="gap-2 btn-xs">
										<FiEye /> Open
									</Button>
								),
							}))}
							headers={[
								{ key: "id", label: "ID" },
								{ key: "merchant", label: "Merchant" },
								{ key: "category", label: "Category" },
								{ key: "amount", label: "Amount" },
								{ key: "date", label: "Expense Date" },
								{ key: "createdAt", label: "Submitted On" },
								{ key: "status", label: "Status" },
								{ key: "action", label: "Action" },
							]}
						/>
					}
					card={<CardsRenderer items={Receipts.receipts} render={(receipt, i) => <Receipt onReload={() => fetchReceipts(ReceiptStatus.value, Query)} onDrag={setDragging} key={i} {...receipt} />} />}
				/>
				<ViewReceipt onReload={() => fetchReceipts(ReceiptStatus.value, Query)} receiptId={OpenId} onClose={() => setOpenId()} />
			</div>
			<BottomBar>
				<div className="w-full flex-center-between">
					<div className="align-center gap-x-4">
						<AddReceipt onReload={() => fetchReceipts(ReceiptStatus.value, Query)} />
						<h5>
							{Receipts.count} Receipt {ReceiptStatus.label}
						</h5>
					</div>
					<div>
						<Pagination active={Query.page} total={Receipts.pages} onChange={(page) => setQuery({ ...Query, page })} />
					</div>
				</div>
			</BottomBar>
		</>
	);
}

export default WithMe;

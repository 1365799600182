import React from "react";
import { Button, ButtonGroup, Container, DatePicker, EditNationalId, EditPassport, EditableInput, KeyValue, UploadPhoto, Toast, ViewYourPolicy } from "../../../../Components";
import { FiLogOut } from "react-icons/fi";
import useUserProfile from "../../../../Hooks/useUserProfile";
import { validatePhoneNumber } from "../../../../Utils/utils";
import { apis, patch } from "../../../../Utils/axios";
import moment from "moment";
import { useClientSettings } from "../../../../Hooks/useClientSettings";
import { TbUsers } from "react-icons/tb";

function Profile() {
	const { isEnabled } = useClientSettings();
	const { profile, reload } = useUserProfile();
	const { identification, fName, lName, phone, email, photo, dob, gender, team, office } = profile;

	const onUpdate = async (obj) => {
		if (obj.phone && !(await validatePhoneNumber(obj.phone))) {
			return Toast.error("Please enter valid phone number");
		}
		patch(apis.updateMyProfile, obj, (r, e) => {
			if (r) {
				reload();
				Toast.success(`Profile updated`);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
			}
		});
	};

	// const updateDocuments = (obj) => {
	// 	const files = [...(identification?.files || []), obj.file.url];
	// 	post(apis.updateMyIdentification, { files }, (r, e) => {
	// 		if (r) {
	// 			reload();
	// 			Toast.success(`Document added`);
	// 		} else if (e) {
	// 			console.log(e);
	// 			Toast.handleError(e);
	// 		}
	// 	});
	// };

	return (
		<div className="mt-4 md:gap-x-4 md:grid md:grid-cols-12">
			<div className="flex flex-col col-span-3 gap-4">
				<Container variant="outlined" className="flex flex-col flex-center">
					<UploadPhoto
						name={fName}
						photo={photo}
						onUpdate={(obj) => {
							onUpdate({ photo: obj.file.url });
						}}
					/>
					<div className="mt-4 text-xs text-secondary">Last login at 11:00 AM</div>
					<Button variant="outlined" className="w-full mt-2 btn-sm">
						<FiLogOut className="mr-2" />
						Sign out
					</Button>
				</Container>
				{/* {isEnabled("ORGANIZATION") && (
					<Container variant="outlined" className="flex flex-col flex-center">
						<div className="w-full flex-center-between">
							<h5>
								<FaWalking className="inline-item" /> Out of Office
							</h5>
							<Switch enabled={OutOffOffice} setEnabled={setOutOffOffice} />
						</div>
						<div className="mt-2 text-label">Out of office for a few days you can forward your approvals to someone else.</div>
						{OutOffOffice && (
							<Button variant="outlined" className="w-full mt-4 btn-sm">
								Select Forward User
							</Button>
						)}
					</Container>
				)} */}
				<ViewYourPolicy />
			</div>
			<div className="col-span-9">
				<Container variant="outlined">
					<h4 className="mb-4">Details</h4>
					<KeyValue label="Email" className="mt-6">
						{email}
					</KeyValue>
					<KeyValue label="First Name" className="mt-2">
						{fName}
					</KeyValue>
					<KeyValue label="Last Name" className="mt-2">
						{lName}
					</KeyValue>
					<KeyValue label="Phone" className="mt-2">
						<EditableInput type="number" required onUpdate={(phone) => onUpdate({ phone })} value={phone} label="Phone Number" />
						{phone}
					</KeyValue>
					<KeyValue label="Gender" className="mt-2">
						<ButtonGroup
							size="xs"
							selected={{
								label: gender,
								value: gender,
							}}
							onClick={(obj) => onUpdate({ gender: obj.value })}
							options={[
								{ label: "Male", value: "MALE" },
								{ label: "Female", value: "FEMALE" },
							]}
						/>
					</KeyValue>
					<KeyValue label="Date of Birth" className="mt-2">
						<DatePicker btnClass="!py-1 !text-xs max-w-min" date={moment(dob).valueOf()} setDate={(dob) => onUpdate({ dob: moment(dob).toDate() })} backDateSelection maxDate={moment().subtract(18, "years").valueOf()} />
					</KeyValue>
					<KeyValue label="Passport Number" className="mt-2">
						<EditPassport reload={reload} identification={identification} photo={photo} />
					</KeyValue>
					<KeyValue label="National ID" className="mt-2">
						<EditNationalId reload={reload} identification={identification} />
					</KeyValue>
				</Container>

				{isEnabled("ORGANIZATION") && (
					<>
						<Container variant="outlined mt-4 !p-0">
							<div className="p-4">
								<h4 className="gap-1 mb-4 align-center">
									<TbUsers />
									Team
								</h4>
								<KeyValue label="Office" className="mt-2">
									{office?.name}
								</KeyValue>
								<KeyValue label="Team" className="mt-2">
									{team?.name}
								</KeyValue>
							</div>
						</Container>
					</>
				)}

				{/* <Container variant="outlined mt-4 !p-0">
					<div className="p-4">
						<div className="flex-center-between">
							<h4 className="gap-1 align-center">
								<TbFiles />
								Travel Documents
							</h4>
							<FileInput url={apis.uploadDocument} data={{}} onUpload={updateDocuments}>
								<div className="cursor-pointer btn btn-xs btn-outlined">+ Add Document</div>
							</FileInput>
						</div>
						<div className="mt-4">{identification?.files?.length > 0 ? <Attachments files={identification?.files || []} /> : <div className="text-label">No Documents added</div>}</div>
					</div>
				</Container> */}
			</div>
		</div>
	);
}

export default Profile;

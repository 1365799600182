import React from "react";
import { FaPlane } from "react-icons/fa";
import { StampTo24hr } from "../../../../../../../../Utils/utils";
import { Img, Modal } from "../../../../../../../../Components";

function Layovers({ segments, onClose = () => {} }) {
	return (
		<>
			<Modal contentClass="!bg-light" open={segments} onClose={onClose}>
				<h2 className="p-4">{segments?.length - 1} Layover</h2>
				<div className="flex flex-col gap-2 overflow-hidden rounded-lg">
					{segments?.map((segment, i) => {
						return (
							<>
								{i !== 0 && (
									<div key={i} className="text-center text-label">
										{segments[i].layoverTime} Layover at {segment.departureCode}
									</div>
								)}
								<div key={i} className={`flex-grow p-4 align-center gap-x-4 border-t border-b bg-canvas`}>
									<div className="">
										<Img src={segment.logo} alt={segment.airlineCode} className="w-10 h-10 rounded-full !bg-canvas" />
										<div className="mt-2 text-xs text-secondary whitespace-nowrap">
											{segment.airlineName} <br />
											{segment.airlineCode}-{segment.flightNumber}
										</div>
									</div>
									<div className="max-w-[8rem]">
										<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(segment.departureTime)}</div>
										<div className="text-sm text-secondary">{segment.departureCode}</div>
										<div className="text-xs text-secondary">{segment.departureTerminal}</div>
									</div>
									<div className="flex-grow min-w-[5rem]">
										<div className="mb-2 text-xs text-center text-secondary whitespace-nowrap">{segment.duration}</div>
										<div className="align-center">
											<div className="w-2 h-2 border rounded-full border-dark" />
											<div className="flex-grow border-t border-dashed border-muted" />
											<FaPlane />
											<div className="flex-grow border-t border-dashed border-muted" />
											<div className="w-2 h-2 border rounded-full border-dark" />
										</div>
									</div>
									<div className="max-w-[8rem]">
										<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(segment.arrivalTime)}</div>
										<div className="text-sm text-secondary">{segment.arrivalCode}</div>
										<div className="text-xs text-secondary">{segment.arrivalTerminal}</div>
									</div>
								</div>
							</>
						);
					})}
				</div>
			</Modal>
		</>
	);
}

export default Layovers;

import React, { useState } from "react";
import { Badge, Img, Spinner } from "../../../../../../../../Components";
import { FiInfo } from "react-icons/fi";
import Layovers from "../../../Oneway/Result/Components/layovers";
import { StampTo24hr } from "../../../../../../../../Utils/utils";
import { FaPlane } from "react-icons/fa";
import moment from "moment";
import { MdLuggage, MdShoppingBag } from "react-icons/md";

function FlightCard({ segments, duration, baggage }) {
	const departure = segments[0];
	const arrival = segments[segments.length - 1];
	const [ShowLayovers, setShowLayovers] = useState();

	const { departureTerminal, logo, departureTime, airlineName, airlineCode, flightNumber, departureCode, departureAirport, arrivalAirport } = departure || {};
	const { arrivalTime, arrivalCode, arrivalTerminal } = arrival || {};

	if (segments.length)
		return (
			<div className="mt-4 overflow-hidden border rounded-lg fade-in-up">
				<div className="p-4 flex-center-between bg-light">
					<div className="flex">
						<h4 className="mr-4">{moment(departureTime).format("DD MMM YYYY")}</h4>
						<h4 className="text-secondary">
							{departureCode} - {arrivalCode}
						</h4>
					</div>
					{baggage && (
						<div className="gap-2 text-xs align-center">
							<Badge>
								<MdShoppingBag className="mr-1" /> Cabin {baggage[`${departureCode}-${arrivalCode}`]?.[0]?.cabin}
							</Badge>
							<Badge>
								<MdLuggage className="mr-1" /> CheckIn {baggage[`${departureCode}-${arrivalCode}`]?.[0]?.checkIn}
							</Badge>
						</div>
					)}
					{/* <Badge variant="success">Full Refundable</Badge> */}
				</div>
				<div className="col-span-2">
					<div className={`flex-grow p-4 align-center gap-x-4`}>
						<div className="">
							<Img src={logo} alt={airlineCode} className="w-10 h-10 rounded-full !bg-canvas" />
							<div className="mt-2 text-xs text-secondary whitespace-nowrap">
								{airlineName} <br />
								{airlineCode}-{flightNumber}
							</div>
						</div>
						<div className="max-w-[8rem]">
							<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(departureTime)}</div>
							<div className="text-sm text-secondary">{departureCode}</div>
							<div className="text-xs text-secondary">{departureAirport}</div>
							{departureTerminal && <div className="text-xs text-secondary">{departureTerminal}</div>}
						</div>
						<div className="flex-grow min-w-[5rem]">
							<div className="mb-2 text-xs text-center text-secondary whitespace-nowrap">
								{Math.floor(duration / 60)}h {duration % 60}m
							</div>
							<div className="align-center">
								<div className="w-2 h-2 border rounded-full border-dark" />
								<div className="flex-grow border-t border-dashed border-muted" />
								<FaPlane />
								<div className="flex-grow border-t border-dashed border-muted" />
								<div className="w-2 h-2 border rounded-full border-dark" />
							</div>
							<div className="mt-2 text-xs text-center text-secondary whitespace-nowrap">
								{segments.length === 1
									? "Non-Stop"
									: `${segments.length - 1} stop (via ${segments
											.slice(1)
											?.map((segment) => `${segment.departureCode}`)
											.join(", ")})`}
							</div>
						</div>
						<div className="max-w-[8rem]">
							<div className="text-xl font-bold whitespace-nowrap">{StampTo24hr(arrivalTime)}</div>
							<div className="text-sm text-secondary">{arrivalCode}</div>
							<div className="text-xs text-secondary">{arrivalAirport}</div>
							{arrivalTerminal && <div className="text-xs text-secondary">{arrivalTerminal}</div>}
						</div>
					</div>
					{segments.length > 1 && (
						<div onClick={() => setShowLayovers(segments)} className="gap-2 p-2 border-t rounded-bl cursor-pointer flex-center bg-light hover:bg-canvas">
							<div className="gap-1 text-xs text-secondary flex-center">
								<FiInfo />
								Layovers
							</div>
							{segments.map((segment, i) => (
								<>
									<div key={i} className="w-5 h-1 mt-1 border-t border-dotted border-secondary" />
									<Img src={segment.logo} alt={airlineCode} className="w-6 h-6 rounded-full" />
								</>
							))}
						</div>
					)}
				</div>
				<Layovers segments={ShowLayovers} onClose={() => setShowLayovers()} />
			</div>
		);

	return (
		<div className="flex-center">
			<Spinner />
		</div>
	);
}

export default FlightCard;

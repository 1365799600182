import React from "react";
import { Badge, Button, OutOfPolicyBadge } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ selected, flight, fare, familyFare, onSelect, approval }) {
	const { checkUserPermission } = useUserConfig();
	const approvedAmount = approval?.amount;
	const { fareKey, price, fareName, inPolicy, violations, currency } = fare;
	const active = selected && selected.selectedFare.fareKey === fareKey && selected.selectedFare.price === price;
	const askApproval = !inPolicy && !checkUserPermission("TA") && (approvedAmount ? price > Number(approvedAmount) : true);

	if (price > 0)
		return (
			<>
				<div className="flex-1 h-full p-4 space-y-4 text-center flex-center">
					<div>
						{askApproval ? <OutOfPolicyBadge violations={[...violations]} /> : <></>}
						{fareName && <Badge>{fareName}</Badge>}
						<h2>
							{currency} {price}
						</h2>
						{onSelect &&
							(active ? (
								<Button onClick={() => onSelect()} className="m-auto mt-2 btn-xs">
									Remove
								</Button>
							) : (
								<Button onClick={() => onSelect(fare)} variant="outlined" className="m-auto mt-2 btn-xs">
									Select
								</Button>
							))}
					</div>
				</div>
			</>
		);
	return null;
}

export default Fare;

import React, { useEffect, useRef, useState } from "react";
import { useUserPreference } from "../../../../../../Hooks/useUserPreference";
import FareSummary from "./fareSummary";
import { Loader, Steps, Toast } from "../../../../../../Components";
import ReviewStep from "./Steps/Review";
import Payment from "./Steps/payment";
import Travellers from "./Steps/travellers";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserPolicy } from "../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../Hooks/useUserConfig";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";

function BookStay() {
	const NAVIGATE = useNavigate();
	const LOCATION = useLocation();
	const { checkUserPermission } = useUserConfig();
	const { travelPolicy } = useUserPolicy();
	const { showSidePane } = useUserPreference();
	const { isEnabled } = useClientSettings();
	const isTA = checkUserPermission("TA");

	const { adminBooking = false } = (travelPolicy?.domesticStay?.adminBooking && !isTA) || {};
	const { hotelId, bookingCode, roomCode, checkIn, checkOut, occupancy, approvalId, requestId, room, hotel } = LOCATION.state || {};

	//Check for direct page access
	if (!hotelId || !bookingCode || !roomCode) {
		Toast.error("Try new booking");
		NAVIGATE(-1);
	}

	if (adminBooking) {
		Toast.error("Self booking is not allowed");
		NAVIGATE(-1);
	}

	const GET_CUSTOM_FIELDS = useRef();
	const GO_NEXT = useRef();

	const [HotelPolicy, setHotelPolicy] = useState({});
	const [SelectedTravellers, setSelectedTravellers] = useState([]);
	const [CustomFieldsData, setCustomFieldsData] = useState({});
	const [CostCenter, setCostCenter] = useState();

	useEffect(() => {
		showSidePane(false);
		return () => {
			showSidePane(true);
		};
	}, []);

	const handleStep = (step) => {
		if (step === 2) {
			if (!CostCenter && isEnabled("COST_CENTER") && !isTA) {
				Toast.error("Cost center is required");
				return false;
			}
			const customFields = GET_CUSTOM_FIELDS?.current?.();
			if (!customFields) {
				return false;
			}
			setCustomFieldsData(customFields);
		}
		return true;
	};

	return (
		<>
			<Loader />
			<div className="grid grid-cols-4 p-4 mb-8 gap-x-4">
				<div className="col-span-3">
					<Steps
						goNext={GO_NEXT}
						onChange={handleStep}
						steps={[
							{
								heading: "Review your Stay",
								description: "Review your stay details",
								content: <ReviewStep setCostCenter={setCostCenter} costCenter={CostCenter} getCustomFields={GET_CUSTOM_FIELDS} {...{ hotel, room, hotelPolicy: HotelPolicy, checkIn, checkOut, occupancy }} />,
							},
							//For travel admin only
							{
								heading: `Travellers (${SelectedTravellers?.length} / ${occupancy?.length})`,
								description: "Select one traveller for each room",
								content: <Travellers {...{ approvalId, requestId }} max={occupancy?.length} passengers={SelectedTravellers} setPassengers={setSelectedTravellers} />,
							},
							{
								heading: "Payment",
								description: `Pay ${Math.ceil(room.amount)} to completed booking`,
								content: <Payment guests={SelectedTravellers} costCenterId={CostCenter?.value} customFields={CustomFieldsData} {...{ hotelId, bookingCode, roomCode, checkIn, checkOut, occupancy, approvalId, requestId, room, hotel }} />,
							},
						]}
					/>
				</div>
				<div className="col-span-1">
					<FareSummary
						hotelPolicy={HotelPolicy}
						setHotelPolicy={setHotelPolicy}
						{...{
							hotelId,
							bookingCode,
							roomCode,
							checkIn,
							checkOut,
							occupancy,
							hotel,
							room,
						}}
					/>
				</div>
			</div>
		</>
	);
}

export default BookStay;

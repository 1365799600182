import React, { useState } from "react";
import FlightCard from "./Components/flightCard";
import FareCard from "./Components/fareCard";
import Sort from "./Components/sort";
import { classifyTime } from "../../../../../../../Utils/utils";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";
import { apis, post } from "Utils/axios";
import { Button, Toast } from "Components";
import { FaEye } from "react-icons/fa";
import moment from "moment";

function FlightResult({ approval, filters = {}, onSelect = () => {}, SelectedFlight, flights = [], fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId }) {
	const { travelPolicy } = useUserPolicy();
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticFlight || {};

	//Family Fare
	const [SelectedFamilyFare, setSelectedFamilyFare] = useState();
	const [FamilyFares, setFamilyFares] = useState([]);

	const [LoadingFamilyFare, setLoadingFamilyFare] = useState(false);

	const [SortBy, setSortBy] = useState({
		type: "price",
		order: "asc",
	});

	const loadFamilyFare = async (vendor, flight, fare) => {
		setLoadingFamilyFare(fare.fareKey);
		if (vendor === "Verteil") {
			setFamilyFares(flight.familyFares);
			setLoadingFamilyFare(false);
			setSelectedFamilyFare(fare.fareKey);
		}
		if (vendor === "Amadeus") {
			getFamilyFare(flight, fare);
		}
	};

	const getFamilyFare = async (flight, fare) => {
		post(
			apis.getFamilyFare,
			{
				from: fromCode,
				to: toCode,
				date: moment(date).format("DD-MMM-YYYY"),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: fare?.fareKey,
				flightSearchKey: flight?.sid,
			},
			(r, e) => {
				setLoadingFamilyFare(false);
				if (r) {
					setFamilyFares(r);
					setSelectedFamilyFare(fare.fareKey);
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	const handleSelect = (selectedFare, flight) => {
		if (selectedFare) {
			onSelect({ ...flight, selectedFare });
		} else {
			onSelect();
		}
	};

	const sortFlights = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.fares[0].price;
			const nextPrice = next.fares[0].price;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "departure") {
			const prevDeparture = prev.segments[0]?.departureTime;
			const nextDeparture = next.segments[0]?.departureTime;
			return SortBy.order === "des" ? nextDeparture - prevDeparture : prevDeparture - nextDeparture;
		} else if (SortBy.type === "arrival") {
			const prevArrival = prev.segments[prev.segments.length - 1]?.arrivalTime;
			const nextArrival = next.segments[prev.segments.length - 1]?.arrivalTime;
			return SortBy.order === "des" ? nextArrival - prevArrival : prevArrival - nextArrival;
		} else if (SortBy.type === "duration") {
			return SortBy.order === "des" ? next.duration - prev.duration : prev.duration - next.duration;
		}
	};

	const filterFlights = (flight) => {
		if (!flight.fares.length) {
			return false;
		}
		const _stops = flight.segments.length - 1;
		const _inPolicy = true;
		const _fullyRefundable = false;
		const _airline = flight.segments[0].airlineName;
		const _time = classifyTime(flight.segments[0].departureTime);
		const _price = flight.fares[0].price;

		const { maxPrice, airlines, stops, time, inPolicy, fullyRefundable } = filters;

		let show = true;

		if (maxPrice && _price > maxPrice) {
			show = false;
		}
		if (stops.length && !stops.includes(_stops)) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (fullyRefundable && !_fullyRefundable) {
			show = false;
		}
		if (airlines.length && !airlines.includes(_airline)) {
			show = false;
		}
		if (time.length && !time.includes(_time)) {
			show = false;
		}
		return show;
	};

	if (!flights.length)
		return (
			<div className="flex-col w-full py-20 flex-center">
				<h1 className="mb-12 text-2xl text-center">No Flights found</h1>
			</div>
		);

	return (
		<>
			<HasApproval approval={approval} />
			<UserChoice />
			<Sort sortBy={SortBy} updateSort={setSortBy} />
			{flights
				.filter(filterFlights)
				.sort(sortFlights)
				.map((flight, index) => {
					const { segments, fares, duration, inPolicy, familyFare, vendor } = flight;
					const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
					const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
					const hasFares = flight?.fares.some((fare) => fare.price > 0);
					if ((!inPolicy && showOutOfPolicyOptions === false) || !hasFares) {
						return null;
					}

					let isLoading = LoadingFamilyFare === fares[0].fareKey;
					let hasBrandedFares = SelectedFamilyFare === fares[0].fareKey;
					let multipleFares = hasBrandedFares ? FamilyFares : null;

					return (
						<div className="mt-4 border rounded-md shadow-sm bg-canvas">
							<div key={index} className="grid grid-cols-5">
								<FlightCard key={index} segments={segments} duration={duration} />
								<div className="flex-1 col-span-2 border-l divide-x flex-center">
									{familyFare && !multipleFares ? (
										<div>
											<h2>✈️&nbsp;&nbsp;&nbsp;Brand Fares</h2>
											<Button loading={isLoading} onClick={() => loadFamilyFare(vendor, flight, fares[0])} variant="outlined" className="w-full gap-2 mt-4 btn-xs">
												<FaEye />
												View Fares
											</Button>
										</div>
									) : (
										(multipleFares || fares).map((fare, i) => {
											return <FareCard loadFamilyFare={loadFamilyFare} approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare, familyFare }} />;
										})
									)}
								</div>
							</div>
						</div>
					);
				})}
		</>
	);
}

export default FlightResult;

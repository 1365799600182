import React, { useState } from "react";
import { Button, Form, Input, Toast } from "../../../../Components";
import { FaArrowRight } from "react-icons/fa";
import { validateEmail, validatePhoneNumber } from "../../../../Utils/utils";

function CreateCompany({ onSubmit = () => {}, onBack = () => {} }) {
	const [Values, setValues] = useState({
		gstin: "",
		address: "",
		name: "",
		email: "",
		phone: "",
	});

	const handleSubmit = async () => {
		if (!Values.gstin || !Values.name || !Values.email || !Values.phone) {
			return Toast.error("Please provide all the details");
		}
		if (!validateEmail(Values.email)) {
			return Toast.error("Please enter a valid email");
		}
		if (!(await validatePhoneNumber(Values.phone))) {
			return Toast.error("Please enter a valid 10 digit phone number");
		}
		onSubmit(Values);
	};

	return (
		<div className="p-10 text-center fade-in-right">
			<h1 className="text-4xl font-bold">Setup Company</h1>
			<div className="mt-2 text-xl font-thin">We will automatically fetch your company details using GSTIN</div>
			<Form onSubmit={handleSubmit} className="mt-8 flex flex-col gap-4 max-w-[400px] m-auto">
				<Input value={Values.gstin} onChange={(gstin) => setValues((prev) => ({ ...prev, gstin: gstin.toUpperCase() }))} required placeholder="Company GSTIN" />
				<Input value={Values.name} onChange={(name) => setValues((prev) => ({ ...prev, name }))} required placeholder="Company Name" />
				<Input value={Values.address} onChange={(address) => setValues((prev) => ({ ...prev, address }))} required placeholder="Company Address" />
				<Input type="email" value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} required placeholder="Company Email" />
				<Input type="number" value={Values.phone} onChange={(phone) => setValues((prev) => ({ ...prev, phone }))} required placeholder="Company Phone" />

				<div className="flex m-auto mt-4 gap-x-2 max-w-min">
					<Button onClick={onBack} className="flex-grow">
						Back
					</Button>
					<Button type="submit" variant="primary" className="flex-grow">
						Continue <FaArrowRight className="ml-2" />
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default CreateCompany;

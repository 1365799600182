import React from "react";
import { Button, ImportBooking } from "../../../../..";
import FlightCard from "../../../../../../Pages/App/Employee/Travel/Flights/Oneway/Result/Components/flightCard";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import { getCabinClassCode, StampToDate } from "../../../../../../Utils/utils";
import { useClientSettings } from "Hooks/useClientSettings";

function FlightRequest({ id, choices, status, cabinClass, onReload = () => {} }) {
	console.log(cabinClass);

	const { currency } = useClientSettings();
	const NAVIGATE = useNavigate();

	const renderFlight = (flight) => {
		return (
			<div className="border rounded-md">
				<FlightCard {...flight} />
			</div>
		);
	};

	const searchFlight = () => {
		const { onwardFlight, returnFlight, flight, type } = choices[0];
		if (type === "round") {
			const { departureCode, departureTime, arrivalCode } = onwardFlight.segments[0];
			const { departureTime: returnDate } = returnFlight.segments[0];

			NAVIGATE(
				`/app/travel/flights/round?fromCity=${departureCode}&from=${departureCode}&fromCode=${departureCode}&toCity=${arrivalCode}&to=${arrivalCode}&toCode=${arrivalCode}&date=${moment(departureTime).startOf("day").valueOf()}&returnDate=${moment(returnDate)
					.startOf("day")
					.valueOf()}&adult=${1}&child=${0}&infant=${0}&cabinClass=${getCabinClassCode(cabinClass)}&requestId=${id}`
			);
		} else {
			const { departureCode, departureTime, arrivalCode } = flight.segments[0];
			NAVIGATE(
				`/app/travel/flights/oneway?fromCity=${departureCode}&from=${departureCode}&fromCode=${departureCode}&toCity=${arrivalCode}&to=${arrivalCode}&toCode=${arrivalCode}&date=${moment(departureTime).startOf("day").valueOf()}&adult=${1}&child=${0}&infant=${0}&cabinClass=${getCabinClassCode(cabinClass)}&requestId=${id}`
			);
		}
	};

	return (
		<>
			{status === "requested" && (
				<div className="flex gap-2">
					<ImportBooking onReload={onReload} type="flight" requestId={id} btnLabel="Import Booking" btnVariant="outlined" />
					<Button onClick={searchFlight} variant="outlined" className="w-full shadow-sm">
						<FiSearch className="mr-2" />
						Search Flight Options
					</Button>
				</div>
			)}
			{choices.map((choice, i) => {
				const { fare, flight, onwardFlight, returnFlight, onwardFare, returnFare, type } = choice;
				return (
					<>
						<div key={i} className="p-4 rounded-lg shadow-sm bg-canvas">
							<h4>User's Choice</h4>
							<div className="flex flex-col mt-4 gap-y-2">
								{type === "oneway" ? (
									<>
										<div className="flex-center-between">
											<label>Departure on {StampToDate(flight.segments[0].departureTime)}</label>
											<label>
												{currency} {fare.price}
											</label>
										</div>
										{renderFlight(flight)}
									</>
								) : type === "round" ? (
									<>
										<div className="flex-center-between">
											<label>Departure on {StampToDate(onwardFlight.segments[0].departureTime)}</label>
											<label>
												{currency} {onwardFare.price}
											</label>
										</div>
										{renderFlight(onwardFlight)}
										<div className="flex-center-between">
											<label>Return on {StampToDate(returnFlight.segments[0].departureTime)}</label>
											<label>
												{currency} {returnFare.price}
											</label>
										</div>
										{renderFlight(returnFlight)}
									</>
								) : null}
							</div>
						</div>
					</>
				);
			})}
		</>
	);
}

export default FlightRequest;

import React, { useEffect, useState } from "react";
import { apis, get } from "../../../../../../../Utils/axios";
import { Badge, Button, Spinner, Toast } from "../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import FareRules from "./rules";
import { MdLuggage } from "react-icons/md";
import { FaBaby, FaBabyCarriage, FaChild, FaPlane, FaUser } from "react-icons/fa";

function FareSummary({ flight = {}, selectedAddons = {} }) {
	const NAVIGATE = useNavigate();

	const segments = flight?.segments || flight?.onwardSegments || [];
	console.log(segments[0]);

	const { selectedFare, passengers, holdId, prevPrice, updatedPrice, addons } = flight;
	const { basePrice, totalTax, price, meal, baggage, seat, currency, discount, serviceCharge } = selectedFare || {};
	const addonsTotal = meal + baggage + seat;

	//Calculating addons if not yet updated
	const baggageTotal = selectedAddons.baggage?.reduce((prev, bag) => prev + bag.count * bag.price, 0);
	const seatTotal = selectedAddons.seat?.reduce((prev, seat) => prev + seat.price, 0);
	const mealTotal = selectedAddons.meal?.reduce((prev, meal) => prev + meal.count * meal.price, 0);
	const calculatedTotal = addons ? addonsTotal : baggageTotal + seatTotal + mealTotal;

	//If addons price is not updated in itinerary, the use calculated
	const total = price + calculatedTotal;

	const [Loading, setLoading] = useState(true);
	const [Rules, setRules] = useState([]);

	useEffect(() => {
		if (holdId) {
			checkFareRules(holdId);
		}
	}, [holdId]);

	const checkFareRules = (holdId) => {
		setLoading(true);
		get(apis.getFareRules, { holdId }, (r, e) => {
			if (r) {
				setRules(r);
				setLoading();
			} else if (e) {
				Toast.handleError(e);
				setLoading();
			}
		});
	};

	const priceChange = (updatedPrice || 0) - (prevPrice || 0);

	return (
		<div className="sticky top-4">
			<Button onClick={() => NAVIGATE(-1)} variant="outlined" className="w-full mb-4">
				<FiArrowLeft className="mr-4" />
				Go Back to Search
			</Button>
			<div className="p-4 border rounded-md">
				<h3>Fare Summary</h3>
				<div className="mt-2 flex-center-between text-label">
					<div>Base Fare</div>
					<div className="text-end">
						{currency} {basePrice}
					</div>
				</div>
				<div className="mt-2 flex-center-between text-label">
					<div>Service Charge</div>
					<div className="text-end">
						{currency} {serviceCharge}
					</div>
				</div>
				<div className="mt-2 flex-center-between text-label">
					<div>Discount</div>
					<div className="text-end text-successText">
						{currency} {discount}
					</div>
				</div>
				<div className="mt-2 flex-center-between text-label">
					<div>Tax</div>
					<div className="text-end">
						{currency} {totalTax}
					</div>
				</div>
				{priceChange > 0 ? (
					<Badge variant="danger">
						The fare has gone up by {currency} {Math.abs(priceChange)}
					</Badge>
				) : priceChange < 0 ? (
					<Badge variant="success">
						🚀 The fare has gone down by {currency} {Math.abs(priceChange)}
					</Badge>
				) : null}
				<div className="p-3 mt-4 font-bold rounded-lg flex-center-between bg-compliment text-accent">
					<div>Booking Amount</div>
					<div className="text-end">
						{currency} {total}
					</div>
				</div>
			</div>
			<div className="p-4 mt-4 border rounded-md">
				<h6>Baggage</h6>
				<div className="mt-2 align-center">
					<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
						<FaUser />
					</div>
					<div>
						<div className="text-xs text-secondary">Check-in: {segments[0].checkinBaggage}</div>
						<div className="text-xs text-secondary">Cabin: {segments[0].cabinBaggage}</div>
					</div>
				</div>
				<div className="mt-2 align-center">
					<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
						<FaChild />
					</div>
					<div>
						<div className="text-xs text-secondary">Check-in: {segments[0].childCheckinBaggage}</div>
						<div className="text-xs text-secondary">Cabin: {segments[0].childCabinBaggage}</div>
					</div>
				</div>
				<div className="mt-2 align-center">
					<div className="w-8 h-8 mr-2 rounded-sm bg-light flex-center">
						<FaBabyCarriage />
					</div>
					<div>
						<div className="text-xs text-secondary">Check-in: {segments[0].infantCheckinBaggage}</div>
						<div className="text-xs text-secondary">Cabin: {segments[0].infantCabinBaggage}</div>
					</div>
				</div>
			</div>
			{/* <div className="flex flex-col gap-4 p-4 mt-4 border rounded-md">
				{Loading ? (
					<div className="flex-center">
						<Spinner />
					</div>
				) : (
					<FareRules rules={Rules} />
				)}
			</div> */}
		</div>
	);
}

export default FareSummary;

import React from "react";
import { Tabs } from "../../../../../Components";
import { Tab } from "@headlessui/react";
import { FaBed, FaCar, FaPlane, FaTrain } from "react-icons/fa";
import FlightRequests from "./Flights";
import StayRequests from "./Stays";
import BusRequests from "./Buses";
import CabRequests from "./Cab";
import TrainRequests from "./Train";

function Requests() {
	return (
		<>
			<div className="px-4 pb-24">
				<FlightRequests />
			</div>
		</>
	);
}

export default Requests;

import React, { useRef, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { Button, CheckoutPayment, ConfettiAnimation, Form, Img, Input, Modal, PeachPayments, Toast } from "../../../../Components";
import LockerImg from "../../../../Assets/Images/intros/locker.png";
import { apis, post } from "../../../../Utils/axios";
import useUserProfile from "../../../../Hooks/useUserProfile";
import { useSearchParams } from "react-router-dom";

function AddMoney({ currency, onReload = () => {} }) {
	const [searchParams] = useSearchParams();
	const { profile } = useUserProfile();
	const [ShowAddMoney, setShowAddMoney] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [OngoingPayment, setOngoingPayment] = useState(false);

	const [Values, setValues] = useState({});

	const [CheckoutURl, setCheckoutURl] = useState();
	const PEACH_PG = useRef();

	const createOrder = () => {
		setLoading(true);
		post(
			apis.orderWalletRecharge,
			{
				amount: Values.amount,
				currency: currency,
			},
			(r, e) => {
				if (r) {
					setOngoingPayment(true);
					if (currency === "ZAR") {
						//Peach Payments
						PEACH_PG.current({
							amount: Values.amount,
							orderId: r.orderId,
							checkoutId: r.checkoutId,
							user: {
								name: profile ? `${profile.fName} ${profile.lName}` : "",
								email: profile?.email,
								phone: profile?.phone,
							},
						});
					}
					if (currency === "AED") {
						//Checkout.com
						setCheckoutURl({
							url: r.checkoutUrl,
							orderId: r.orderId,
						});
					}
				} else {
					Toast.handleError(e);
					setOngoingPayment(false);
				}
			}
		);
	};

	const addMoney = (txnData) => {
		let data;
		if (currency === "ZAR") {
			const { resultDetails, signature, result, checkoutId, currency, merchantTransactionId } = txnData;
			data = {
				currency,
				signature,
				checkoutId,
				result: {
					orderId: merchantTransactionId,
					resultDetails,
					result,
				},
			};
		} else if (currency === "AED") {
			const { transactionId } = txnData;
			data = {
				currency,
				transactionId,
			};
		}
		post(apis.updatePaymentDetails, data, (r, e) => {
			if (r) {
				onReload();
				onClose();
			} else {
				Toast.handleError(e);
			}
		});
	};

	const onClose = () => {
		setShowAddMoney(false);
		setOngoingPayment(false);
		setValues({});
		setCheckoutURl();
		setLoading(false);
	};

	return (
		<>
			<div onClick={() => setShowAddMoney(!ShowAddMoney)} className="p-4 text-white transition-all cursor-pointer bg-accent hover:bg-primary rounded-xl flex-center">
				<h1 className="gap-4 text-lg align-center">
					<FaWallet /> + Add Money
				</h1>
			</div>
			<Modal open={ShowAddMoney} onClose={!OngoingPayment ? onClose : () => {}}>
				{!OngoingPayment && (
					<div className="p-6 text-center">
						<Img src={LockerImg} alt="Locker" className="max-w-[10rem] m-auto mb-6" />
						<h1>Add money to wallet</h1>
						<div className="text-label">Enter the amount you want to add to your wallet</div>
						<Form onSubmit={createOrder} className="mt-4">
							<Input value={Values.amount} onChange={(amount) => setValues((prev) => ({ ...prev, amount }))} placeholder={`Enter amount in ${currency}`} name="amount" type="number" required />
							<div className="gap-2 flex-center">
								<Button loading={Loading} variant="primary" className="mt-4" type="submit">
									Pay Now
								</Button>
								<Button variant="outlined" className="mt-4" onClick={onClose}>
									Cancel
								</Button>
							</div>
						</Form>
					</div>
				)}
				<CheckoutPayment url={CheckoutURl?.url} onClose={onClose} onSuccess={addMoney} />
				<PeachPayments openPg={PEACH_PG} onSuccess={addMoney} onClose={onClose} />
			</Modal>
			<ConfettiAnimation show={searchParams.get("recharge") === "success"} />
		</>
	);
}

export default AddMoney;

import React from "react";
import { Badge } from "../../../../../../../../Components";
import { StampTo24hr, StampToDate } from "../../../../../../../../Utils/utils";
import moment from "moment";

function FlightItinerary({ onwardSegments, returnSegments }) {
	const onwardJourney = onwardSegments ? onwardSegments[0] : null;
	const returnJourney = returnSegments ? returnSegments[0] : null;

	return (
		<>
			<h6>Itinerary</h6>
			<div className="pl-4 mt-4">
				{onwardJourney && (
					<div className="flex text-sm">
						<div className="border border-l" />
						<div className="relative pt-6 pl-8 align-center">
							<div className="absolute p-1 -left-6 bg-canvas">
								<img src={onwardJourney.logo} alt="airline-logo" className="w-10 h-10 mr-2 rounded-full" />
							</div>
							<div>
								<h4>Departure</h4>
								<Badge className="mt-2">{moment.utc(onwardJourney.departureTime).format("HH:mm DD MMM")}</Badge>
								<div className="mt-2 text-xs">
									{onwardJourney.airlineName} {onwardJourney.airlineCode}-{onwardJourney.flightNumber}
								</div>
								<div className="mt-1 text-xs text-secondary">
									{onwardJourney.departureTerminal}, {onwardJourney.departureAirport}
								</div>
							</div>
						</div>
					</div>
				)}
				{returnJourney && (
					<div className="flex text-sm">
						<div className="border border-l" />
						<div className="relative pt-6 pl-8 align-center">
							<div className="absolute p-1 -left-6 bg-canvas">
								<img src={returnJourney.logo} alt="airline-logo" className="w-10 h-10 mr-2 rounded-full" />
							</div>
							<div>
								<h4>Return</h4>
								<Badge className="mt-2">{moment.utc(returnJourney.departureTime).format("HH:mm DD MMM")}</Badge>
								<div className="mt-2 text-xs">
									{returnJourney.airlineName} {returnJourney.airlineCode}-{returnJourney.flightNumber}
								</div>
								<div className="mt-1 text-xs text-secondary">
									{returnJourney.departureTerminal}, {returnJourney.departureAirport}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default FlightItinerary;

import React from "react";
import { Attachments, KeyValue } from "../../..";
import { StampToDate } from "../../../../Utils/utils";
import { useClientSettings } from "Hooks/useClientSettings";

function ReceiptApproval({ receipt, data }) {
	console.log(data);

	const { currency } = useClientSettings();
	const { id, date, amount, category, description } = receipt;

	return (
		<>
			<div className="p-4 rounded-lg shadow-sm bg-canvas">
				<h4>Receipt Details</h4>
				<div className="flex flex-col mt-4 gap-y-2">
					<KeyValue label="Receipt ID">{id}</KeyValue>
					<KeyValue label="Date">{StampToDate(date)}</KeyValue>
					<KeyValue label="Amount">
						{currency} {amount}
					</KeyValue>
					<KeyValue label="Category">{category?.name}</KeyValue>
					<KeyValue label="Purpose">{description}</KeyValue>
				</div>
				{receipt.attachments?.length > 0 && (
					<>
						<h4 className="mt-4">Attachments</h4>
						<Attachments className="mt-4" files={receipt.attachments} />
					</>
				)}
			</div>
		</>
	);
}

export default ReceiptApproval;
